
import React, { useState } from 'react';

import {
    Card,
    Input,
    Button,
    Typography,
    IconButton,
    Textarea,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Avatar,

} from "@material-tailwind/react";



import { } from "@heroicons/react/24/outline";

import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { showAlert } from './Alert.jsx';
import { sendEmail } from './SendEmail';

import agro_avatar from "./agro_avatar.png"
import user_avatar from "./user_avatar.png"

export default function ChatCard(props) {


    const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);

    const [name, setName] = useState(props.name);
    const [email, setEmail] = useState(props.email);
    const [message, setMessage] = useState(props.message);
    const [subject, setSubject] = useState(props.subject);
    const [conversation,setConversation] = useState([]);
    const [mySide,setMySide] = useState("user");


    
    
    
    const [sendDisabled, setSendDisabled] = useState(false);



    function cancelClose() {
        props.close();
    }


    function messageUpdate(e) {
        setMessage(e);
    }



    function handleDataResponse(data) {

        console.log(data);
        setSendDisabled(false); //tymczasowo odblokuj przycisk wyślij
        if (data.status === "ok") {
            showAlert("green", data.message, 4000);
            setRefreshAfterExit(true);

            if (closeOnSend) { props.close(refreshAfterExit); }

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 4000);
        }
    }



    function handleSubmit(e) {
        e.preventDefault();
        setSendDisabled(true); //tymczasowo zablokuj przycisk wyślij
        let html="<html>" + message + "</html>"
        sendEmail(session, email, subject, message, html, handleDataResponse); //wyślij email
    }



    function SingleChatLine(props) {
        var content=props.content;
        var author=props.side;
        var myavatar;
        var otheravatar;
        if (mySide=="admin") {
            myavatar=agro_avatar;
            otheravatar=user_avatar;
        }
        else
        {
            myavatar=user_avatar;
            otheravatar=agro_avatar;
        }

        return (
            <ListItem key={props.index} className='p-0 bg-gray-100'>

            <ListItemPrefix className='w-[4rem]  p-0 mx-1'>
            {mySide==author &&               
                <Avatar size="md" color="light-green" className='m-0 p-1 border border-green-200 bg-white' src={myavatar}></Avatar>
            }
            </ListItemPrefix>

            <Typography className='m-0 p-1 w-full text-xs bg-white min-h-[3rem] rounded-lg border border-blue-200'>{content}</Typography>

            <ListItemSuffix className='w-[4rem] p-0 mx-1'>
            {mySide!=author &&               
                <Avatar size="md" color="light-green" className='w-[4rem] p-1 border border-blue-200 bg-white' src={otheravatar}></Avatar>
            }
            </ListItemSuffix>    

            </ListItem>
        )
    } 





    return (
        <div className='absolute w-full h-full top-0 items-center justify-cener m-auto z-[30] flex place-content-center bg-black/50 '>

            <Card color="white" className="w-full max-w-[24rem] mx-auto rounded-lg max-h-[80vh] overflow-y-none" shadow={false}>

                <div className="flex items-center justify-between gap-1 w-auto bg-gradient-to-br from-teal-500/75 to-cyan-500/75 pt-1 pb-1 rounded-t-lg">
                    <Typography color="white" variant="h6" className="mt-2 pl-12 font-normal mx-auto">
                        Agro-Technology Chat
                    </Typography>
                    <IconButton variant='text' className='right-2' color="white" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>

                </div>
                    <div className="mb-2 flex flex-col w-full items-center justify-center bg-gray-100">

                        <List className='w-full bg-gray-100 py-1 px-1 overflow-y-auto'>
                            
                            <ListItem className='p-0 bg-gray-100'>
                                <ListItemPrefix className='w-[4rem]  p-0 mx-1'>
                                <Avatar size="md" color="light-green" className='m-0 p-1 border border-green-200 bg-white' src={agro_avatar}></Avatar>
                                </ListItemPrefix>
                                <Typography className='m-0 p-1 w-full text-xs bg-white min-h-[3rem] rounded-lg border border-blue-200'>Dzień dobry, w czym możemy pomóc ?</Typography>


                                <ListItemSuffix className='w-[4rem] p-0 mx-1'>
                                <Avatar size="md" color="light-green" className='w-[4rem] p-1 border border-blue-200 bg-white' src={user_avatar}></Avatar>
                                </ListItemSuffix>    
                            </ListItem>


                            <ListItem className='p-0 bg-gray-100'>
                                <ListItemPrefix className='w-[4rem]  p-0 mx-1'>
                                {true==false &&
                                <Avatar size="md" color="light-green" className='m-0 p-1 border border-green-200 bg-white' src={agro_avatar}></Avatar>
                                }                                    
                                </ListItemPrefix>
                                <Typography className='m-0 p-1 w-full text-xs bg-white min-h-[3rem] rounded-lg border border-blue-200'>Dzień dobry, w czym możemy pomóc ?</Typography>


                                <ListItemSuffix className='w-[4rem] p-0 mx-1'>
                                <Avatar size="md" color="light-green" className='w-[4rem] p-1 border border-blue-200 bg-white' src={user_avatar}></Avatar>
                                </ListItemSuffix>    
                            </ListItem>


                            <ListItem className='p-0 bg-gray-100'>
                                <ListItemPrefix className='w-[4rem]  p-0 mx-1'>
                                <Avatar size="md" color="light-green" className='m-0 p-1 border border-green-200 bg-white' src={agro_avatar}></Avatar>
                                </ListItemPrefix>
                                <Typography className='m-0 p-1 w-full text-xs bg-white min-h-[3rem] rounded-lg border border-blue-200'>Dzień dobry, w czym możemy pomóc ?</Typography>


                                <ListItemSuffix className='w-[4rem] p-0 mx-1'>
                                <Avatar size="md" color="light-green" className='w-[4rem] p-1 border border-blue-200 bg-white' src={user_avatar}></Avatar>
                                </ListItemSuffix>    
                            </ListItem>

                            <SingleChatLine index={1} side="admin" content="admin side text" />        
                            <SingleChatLine index={2} side="admin" content="second admin side text" />        
                            <SingleChatLine index={3} side="user" content="user side text" />        

                        </List>

                        <div className="mx-2 my-2 p-0 w-full max-w-[22rem]">
                        <Textarea label="Wiadomość" className="m-0 p-0 w-full border border-blue-200 bg-white" value={message} onChange={e => messageUpdate(e.target.value)} />
                        </div>

                        <Button className="items-center max-w-[8rem] mb-2 mt-4 justify-center bg-gradient-to-br from-teal-500 to-cyan-500 mx-32" disabled={sendDisabled} data-refresh={true} fullWidth type="submit">
                            Wyślij
                        </Button>
                    </div>

            </Card>

        </div>
    );
}

