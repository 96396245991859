import React, { useState, useRef } from 'react';
import { UseMap } from 'react-map-gl';


import {
    Alert,
    Badge,
    Card,
    Input,
    Checkbox,
    Button,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Typography,
    IconButton,
    Radio,
    Tab,
    Tabs,
    TabsBody,
    TabsHeader,
    TabPanel,
    Select,
    Option,
    Textarea,
    Tooltip

} from "@material-tailwind/react";

import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    ArrowTopRightOnSquareIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
    PencilIcon,
    TrashIcon,
    SparklesIcon,
    RectangleGroupIcon,
    BeakerIcon,
    FolderPlusIcon,
    //DocumentIcon,
    DocumentChartBarIcon,
    PaperClipIcon,
    FunnelIcon,

} from "@heroicons/react/24/outline";

import { FolderMinusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ShapeUnziper from './ShapeUnziper.jsx';

import * as turf from '@turf/turf'

import MapboxPanel from './MapBoxPanel.jsx';
import { json } from 'react-router-dom';
import Datepicker from "react-tailwindcss-datepicker";
import ConfirmDialog, { QueryDataDialog } from './ConfirmDialog.jsx';
import { showAlert } from './Alert.jsx';
import { geoportalGetParcelPolygon } from './geoportal.js';
import FileUploadCard from './FileUploadCard.jsx';
import { getFieldFiles } from './AdminTools.jsx';
import DocumentIcon from './DocumentIcon.jsx';
import { features } from 'process';
import { matchFieldsResults } from './GeoTools.jsx';


export default function FieldImportCard(props) {

    const [collection, setCollection] = useState(props.imported);
    const [ownerId, setOwnerId] = useState(props.ownerId);
    const [ownerName, setOwnerName] = useState(props.ownerName);

    const [fields, setFields] = useState(JSON.parse(localStorage.getItem("fields")) || []);
    const [files, setFiles] = useState(JSON.parse(localStorage.getItem("files")) || []);
    const [bounds, setBounds] = useState();

    const [clearOnExit, setClearOnExit] = useState(false);
    const [session, setSession] = useState(localStorage.getItem("session") || null);

    const [refreshAfterExit, setRefreshAfterExit] = useState(false);
    const [closeOnSend, setCloseOnSend] = useState(false);
    const [showConfirmClear, setShowConfirmClear] = useState(false);
    const [sendDisabled, setSendDisabled] = useState(true);

    const emptyGeoJson = {
        "type": "FeatureCollection",
        features: []
    }

    
    let expectFeatures = 0;


    React.useEffect(() => {
        init();
        setTimeout(()=>showGeoJson(collection),400);
    }, []);

    React.useEffect(() => {
        //applyBoundingBox(bounds); //panel map box only
        //console.log("Effect bounds")
    }, [bounds]);



    function init() {

        if (collection) {
            var fieldFeatures = collection.features;
            var index=1;
            
            fieldFeatures.forEach(element => {
                var name = "";
                element.properties.value = 5; //by defaault all selected
                element.properties.selected = true;
                
                if (element.properties.name) name = element.properties.name;
                    else if (element.properties.SL_NAME) name = element.properties.SL_NAME;
                        else if (element.properties.nr_dzialki) name = element.properties.nr_dzialki;
                if (name=="") { name = "Pole " + (index++); }
                element.properties.name = name;
            })
        }

        setCollection(collection);
        applyCollection(collection);
        showGeoJson(collection);    

        
    }


    function showGeoJson(collection, refresh) {

        let bounds = turf.bbox(collection);
        let resizedbounds = resizeBoundingBox(bounds);

        console.log("resizedbounds");    
        console.log(resizedbounds);    
        applyBoundingBox(resizedbounds); //panel map box only
        setBounds(resizedbounds);

        if (refresh) setShowPreview(true);
    }




    function applyCollection(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: collection }));
    }

    function applyFeatures(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: { features: collection } }));
    }

    function applyFocused(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: { focused: collection } }));
    }

    function applyLabFeatures(collection) {
        //localStorage.setItem('featureCollection', JSON.stringify(collection));
        dispatchEvent(new CustomEvent("featureCollectionChangedPanel", { detail: { labFeatures: collection } }));
    }


    function applyBoundingBox(bounds) {
        //localStorage.setItem('boundingBox', JSON.stringify(bounds));
        dispatchEvent(new CustomEvent('boundingBoxChangedPanel', { detail: bounds })); //cool can pass arguments in event this way
    }



    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }


    function handleFileResponse(data) {

        console.log(data);
        setSendDisabled(false); //odblokuj przycisk wyślij
        if (data.status === "ok") {
            showAlert("green", data.message, 2000);
            setRefreshAfterExit(true);

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 3000);
        }
    }


    function handleFieldResponse(data) {

        console.log(data);
        setSendDisabled(false); //odblokuj przycisk wyślij
        if (data.status === "ok") {
            showAlert("green", data.message, 2000);
            setRefreshAfterExit(true);

        }
        if (data.status !== "ok") {
            showAlert("red", data.message, 3000);
        }
    }



    function finishImport()
    {
        console.log("Finishing Import");
        console.log(collection);

        for (var index=collection.features.length-1; index>=0; index--)
        {
            console.log("index " + index);
            if (!collection.features[index].properties.selected) {
                collection.features.splice(index, 1);
            }
        }
        if (props.onFinish) props.onFinish(collection);
    }




    function zeroPad(n) {
        return n > 9 ? "" + n : "0" + n;
    }




    function resizeBoundingBox(bounds) {
        var point1 = turf.point([bounds[0], bounds[1]]);
        var point2 = turf.point([bounds[2], bounds[3]]);
        var bearing = turf.bearing(point1, point2);
        var distance = turf.distance(point1, point2);
        var p1 = turf.destination(point1, -0.1 * distance, bearing);
        var p2 = turf.destination(point2, 0.1 * distance, bearing);
        var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
        return box;
    }





    function showGeoJson(collection, refresh) {

        
        let bounds = turf.bbox(collection);
        let resizedbounds = resizeBoundingBox(bounds);

        console.log("resizedbounds");    
        console.log(resizedbounds);    
        applyBoundingBox(resizedbounds); //panel map box only


        if (refresh) setShowPreview(true);
    }



    function cancelClose() {
        console.log("close on send : " + closeOnSend);

        if (clearOnExit) {
            applyCollection(emptyGeoJson);
        }
        props.close(refreshAfterExit);
    }






    function selectFieldItem(e) {
        const idx = e.currentTarget.getAttribute("data-fieldindex");
        console.log('select field' + idx);
        console.log(collection);
        setCollection(collection);
        let feature = collection.features[idx];
        let bounds = turf.bbox(feature);
        let resizedbounds = resizeBoundingBox(bounds);

        //setBounds[bounds];
        applyBoundingBox(resizedbounds); //panel map box only
        applyFeatures(collection);
        //applyFocused(feature);
    }


    function changeFieldChecked(index, checked) {
        
        var feature = collection.features[index];
        var properties = feature.properties;    

        if (checked==true) { properties.value=5; properties.selected=checked; }
        else { properties.value=1; properties.selected=checked;}    

        let bounds = turf.bbox(collection);
        let resizedbounds = resizeBoundingBox(bounds);

        //applyBoundingBox(resizedbounds); //panel map box only
        applyFeatures(collection);
        setCollection(collection);
        
        //applyFocused(feature);
    }

    function changeFieldName(index, name) {
        
        var feature = collection.features[index];
        var properties = feature.properties;    

        properties.name=name;
        setCollection(collection);
        
    }


    function SingleField(props) {
        const [checked,setChecked] = useState(true);
        const [fieldName, setFieldName] = useState(props.name);

        function toggle()
            {
                setChecked(!checked);
                changeFieldChecked(props.index,!checked);
            }

        function changeName(name)
            {
                setFieldName(name);
                changeFieldName(props.index,name);
            }


        return (
            <ListItem key={props.index} className='py-0 px-0 mx-0 text-sm' data-fieldindex={props.index} >
            <ListItemPrefix className='p-0 m-0'>
                <Checkbox size="sm" className='m-auto p-0 w-4 h-4 rounded-md' checked={checked} onChange={toggle}></Checkbox>
            </ListItemPrefix>
            <input value={fieldName} onChange={e => changeName(e.target.value)} className='w-[10rem] h-7 p-1'/>          
            <ListItemSuffix >{props.area}</ListItemSuffix>
            </ListItem>
        )


    }





    function RenderFieldFeatureList(param) {
        console.log('render params');
        console.log(param);
        var index=1;
        
        if (!Array.isArray(param.features.features)) return;
        return (
            param.features.features.map(({ properties, area }, index) => {
                var name = "";
                if (properties.name) name = properties.name;
                if (properties.SL_NAME) name = properties.SL_NAME;
                if (properties.nr_dzialki) name = properties.nr_dzialki;

                if (name=="") { name = "Pole " + (index+1); changeFieldName(index,name); }
                
                
                return (

                    <List className='p-1 mx-0 w-full '>
                    <SingleField index={index} name={name} />
                    </List>

                    )
            } //map
            )
        )
    }








    return (

        <div className='fixed w-full h-full top-0 left-0 items-center justify-center m-auto z-[10] flex place-content-center bg-black/50 '>

            <Card color="white" className="w-auto max-w-[90vw] mx-auto rounded-lg shadow-lg max-h-[94vh] " shadow={true}>

                <div className="flex justify-between gap-2 w-auto mt-2">
                    <Typography color="gray" className="mt-0 pl-12 font-normal mx-auto">
                        Import obszarów
                    </Typography>
                    <IconButton variant='text' className='-top-1 right-2' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
                    </IconButton>
                </div>
                <div className="w-auto flex flex-row items-start gap-2 m-2">


                    <div id="list of client features" className='w-[16rem] max-h-[79vh] overflow-y-auto overflow-x-hidden border border-blue-100 rounded-lg'>

                        <div className="flex justify-between w-auto bg-blue-100 rounded-lg ">
                            <Typography color="Black" className="mt-0 p-2 font-normal mx-auto text-center">
                                Wybierz pola do importu
                            </Typography>
                        </div>

                            <RenderFieldFeatureList features={collection} />

                    </div>



                    <div id="map" className='rounded-lg p-1 bg-blue w-auto h-auto'>
                        <div id="buttons" className='flex flex-row mb-4 h-auto rounded-lg border border-blue-100 p-2 items-center'>

                            <Button className="flex items-center gap-2 mx-auto" size="sm" color="green" onClick={finishImport}>
                                <InboxIcon strokeWidth={2} className="h-4 w-4" />Zaimprtuj wybrane obszary
                            </Button>

                        </div>

                        <div id="map" className='rounded-lg border border-green-100'>
                            <MapboxPanel features={collection} bounds={bounds} min={0} max={10} selected="value" style={{ position: 'relative', width: '50vw', height: '62vh', cornerRadius: "20px", margin: "5px" }} />
                        </div>
                    </div>




                </div>
            </Card>
            
        </div>

    );

}



