import React, { useRef, useState, useEffect } from "react";

import {
    Button,
    Card,
    Input,
    IconButton,
    MenuItem,
    Tooltip
} from "@material-tailwind/react";

import {
    FolderOpenIcon,
    FolderPlusIcon,
    PresentationChartBarIcon,
    ShoppingBagIcon,
    MapIcon,
    ClipboardDocumentListIcon,
    UserCircleIcon,
    UsersIcon,
    Cog6ToothIcon,
    InboxIcon,
    InboxArrowDownIcon,
    PowerIcon,
    DocumentPlusIcon
} from "@heroicons/react/24/outline";

import { unzip } from 'unzipit';


export default function ShapeUnziper(props) {
    const [zipFileName, setZipFileName] = React.useState('');
    const [zipFileSize, setZipFileSize] = React.useState();
    const [zipFileLastMod, setZipFileLastMod] = React.useState();
    const [isSelected, setIsSelected] = useState(false);
    const [dbfArray, setDbfArray] = useState();
    const [shpArray, setShpArray] = useState();
    const [pdfArray, setPdfArray] = useState();
    const [disabled, setDisabled] = useState(props.disabled);
    const [dataArray, setDataArray] = useState();
    const [accepted, setAccepted] = useState(".*,zip,application/zip,application/x-zip,application/x-zip-compressed");
    const [gotFiles, setGotFiles] = useState(0);
    const [iconOnly, setIconOnly] = useState(false);
    const [fullButton, setFullButton] = useState(props.fullbutton);
    const [iconSize, setIconSize] = useState('sm')
    const [iconClassName, setIconClassName] = useState('relative -left-9 top-1 px-4')

    const [toolTip, setToolTip] = useState(props.tooltip || "Wczytaj plik zip z granicami pola");

    const inputFile = useRef(null);
    function handleOpenDialog() {
        inputFile.current.click();
    }

    useEffect(() => {
        if (props.iconOnly) setIconOnly(props.iconOnly);
        if ((props.iconOnly == true) && (!props.fullButton)) {
            setIconClassName('w-32 h-32');
            setIconSize('md');
        }
        if (props.allowpdf) {
            setAccepted(".*,.pdf,zip,application/zip,application/x-zip,application/x-zip-compressed")
        }
        if (props.disabled==true)
        {
            setToolTip("Wybierz właściciela pola");
        }
        else
        {
            setToolTip("Wczytaj plik zip z granicami pola");
        }

    }, []);

    useEffect(() => {
        if ((dbfArray) && (shpArray)) {
            console.log('got both');
            getGeoJson();
        }
    }, [dbfArray, shpArray]);

    useEffect(() => {
        setDisabled(props.disabled);
        if (props.disabled==true)
            {
                setToolTip("Wybierz właściciela pola");
            }
            else
            {
                setToolTip("Wczytaj plik zip z granicami pola");
            }
            
    }, [props.disabled]);


    const handleDbfRead = (e) => {
        setDbfArray(e.target.result);
        setGotFiles(gotFiles + 1);
    }
    const handleShpRead = (e) => {
        setShpArray(e.target.result);
        setGotFiles(gotFiles + 1);
    }

    const handlePdfRead = (e) => {
        setPdfArray(e.target.result);
        if (props.filedata) props.filedata(e.target.result);
    }

    const handleDataRead = (e) => {
        console.log('handle datar read ' + e.target.result);
        setDataArray(e.target.result);
        if (props.filedata) props.filedata(e.target.result);
    }


    const handleFileSelected = (event) => {
        setDbfArray(null);
        setShpArray(null);

        [].forEach.call(event.target.files, file => {
            //console.log(file);
            let fileType = file.name.split('.').pop();
            let matched=false;

            if (fileType == 'dbf') {
                let fileReader = new FileReader();
                fileReader.onloadend = handleDbfRead;
                fileReader.readAsArrayBuffer(file);
                matched=true;
            }

            if (fileType == 'shp') {
                let fileReader = new FileReader();
                fileReader.onloadend = handleShpRead;
                fileReader.readAsArrayBuffer(file);
                setZipFileName(file.name);
                setZipFileSize(file.size);
                setZipFileLastMod(file.lastModified);
                matched=true;
            }

            if (fileType == 'zip') {

                setZipFileName(file.name);
                setZipFileSize(file.size);
                setZipFileLastMod(file.lastModified);
                let fileReader = new FileReader();
                console.log(file);
                fileReader.onloadend = handleDataRead;
                fileReader.readAsArrayBuffer(file);
               
                readZipFiles(file);
                matched=true;
            }

            if (fileType == 'pdf') {
                let fileReader = new FileReader();
                console.log(file);
                fileReader.onloadend = handlePdfRead;
                fileReader.readAsArrayBuffer(file);
                matched=true
            }

            if (matched == false) { //any other file (pdf can be here)
                let fileReader = new FileReader();
                console.log(file);
                fileReader.onloadend = handleDataRead;
                fileReader.readAsArrayBuffer(file);
            }

            if (props.fileinfo) props.fileinfo(file);

        })
    };

    async function readZipFiles(file) {
        const { entries } = await unzip(file);
        // print all entries and their sizes
        for (const [name, entry] of Object.entries(entries)) {
            //console.log(name, entry.size);
            var fileType = name.split('.').pop();
            if (fileType == 'dbf') setDbfArray(await entries[name].arrayBuffer());
            if (fileType == 'shp') setShpArray(await entries[name].arrayBuffer());

        }
    }


    //https://medium.com/@miniceo/front-end-shorts-how-to-read-content-from-the-file-input-in-react-17f49b293909

    var shapefile = require("shapefile");

    function getGeoJson() {
        /*
        console.log("get geojson");
        console.log(shpArray);
        console.log(dbfArray);
        */
        shapefile.open(shpArray, dbfArray)
            .then(source => source.read()
                .then(function log(result) {
                    if (result.done) {
                        props.finished();
                        return;
                    }

                    props.geoJson(result.value); //return geoJson and refresh

                    return source.read().then(log);
                }))
            .catch(error => console.error(error.stack));
    }



    return (
        <div className="flex flex-row text-xs justify-center">
            <input type='file' id='file' ref={inputFile} width="0" accept={accepted} style={{ display: 'none' }} multiple onChange={handleFileSelected} />
            {isSelected ? (
                <div>
                    <p>Nazwa Pliku: {zipFileName}</p>
                    <p>Rozmiar: {zipFileSize}</p>
                </div>
            ) : ''}
            <div className="mx-auto"></div>
            {!iconOnly && <Input size="md" label="Wybierz plik granic" shrink={true} className="shadow-md h-full" value={zipFileName} readOnly={true} disabled={disabled}/>}
            {!fullButton &&
                <Tooltip content={toolTip}>
                    <IconButton size={iconSize} variant='gradient' className={iconClassName} color="green" onClick={handleOpenDialog} disabled={disabled}>
                        <FolderOpenIcon className='w-5 h-5' />
                    </IconButton>
                </Tooltip>
            }
            {fullButton &&
                <Tooltip content={toolTip}>
                    <Button className="flex items-center gap-2 mt-2 ml-2" size="sm" color="green" onClick={handleOpenDialog} disabled={disabled}>
                        <DocumentPlusIcon strokeWidth={2} className="h-4 w-4" />Nowy plik
                    </Button>
                </Tooltip>
            }

        </div>

    )
}