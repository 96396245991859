import * as React from 'react';
import { useState, useRef, useCallback } from 'react';
import Map, { UseMap, NavigationControl, Source, Layer, MapProvider } from 'react-map-gl';


import DrawControl from './draw-control.tsx';
import ControlPanel from './control-panel.tsx';
import { Button,Tooltip, IconButton, Typography } from '@material-tailwind/react';
import * as turf from '@turf/turf';
import { CheckIcon, PencilIcon, XMarkIcon, XCircleIcon, ArrowUpCircleIcon, ChevronDoubleUpIcon, PrinterIcon,InboxArrowDownIcon } from '@heroicons/react/24/outline';



const dataLayer = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': '#e0e060',
    'fill-opacity': 0.8
  }
};



/*
      <Source id="fc" type="geojson" data={props.features}>
        <Layer {...{
          id: 'polygonfill',
          type: 'fill',
          filter: ['all'],
          source: { 'type': 'vector' },
          paint: {
            'fill-color': {
              property: props.selected,
              stops: [
                [1, '#ff0000'],
                [10, '#00ff00']
              ]
            },
            'fill-opacity': 0.75
          }
        }} />

        <Layer {...{
          id: 'polygonlines',
          type: 'line',
          filter: ['all'],
          source: { 'type': 'vector' },
          paint: {
            'line-color': {
              property: props.selected,
              stops: [
                [1, '#ff0000'],
                [10, '#00ff00']
              ]
            },
            'line-width': 1
          }
        }} />
      </Source>

*/


var emptyGeoJson = {
  type: "FeatureCollection",
  features: []
}

const fillStyle = {
  id: 'polygonfill',
  type: 'fill',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'fill-color': {
      property: 'Color',
    },
    'fill-opacity': 0.75
  }
};


const lineStyle = {
  id: 'polygonlines',
  type: 'line',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'line-color': {
      property: 'selected',
      stops: [
        [1, '#ff0000'],
        [5, '#00ff00']
      ]
    },
    'line-width': 1
  }
};

const initialView = {
  longitude: 15.62129,
  latitude: 53.63918,
  zoom: 11
};




export default function MapboxPanel(props) {

  const [boundingBox, setBoundingBox] = JSON.parse(localStorage.getItem("boundingBox"));
  const [style, setStyle] = useState(props.style || { position: 'relative', width: '40vw', height: '60vh' })
  const [color, setColor] = useState(props.color);
  const [selected, setSelected] = useState(props.selected);
  const [min, setMin] = useState(props.min);
  const [max, setMax] = useState(props.max);
  const [med, setMed] = useState((props.max+props.min)/2 || 3);
  const [step, setStep] = useState((props.max-props.min)/4 || 1);
  const [showLegend, setShowLegend] = useState(props.showLegend);
  
  const [features, setFeatures] = useState(props.features);
  const [labFeatures, setLabFeatures] = useState(props.labFeatures);
  const [focused, setFocused] = useState({});
  
  //const [color, setColor] = useState('#ffff80')

  const mapRefPanel = useRef();


  React.useEffect(() => {
    addEventListener('boundingBoxChanged', () => {
      console.log("bbox parse event");
      let bbox = JSON.parse(localStorage.getItem("boundingBox"));
      setBounds(bbox);
    });
    
    addEventListener('boundingBoxChangedPanel', (e) => {
      if (e.detail) { setBounds(e.detail);
        }
    });

    addEventListener('featureCollectionChangedPanel', (e) => {
       if (e.detail.features) { if (mapRefPanel?.current) { mapRefPanel.current.getSource("features").setData(e.detail.features); } 
                                  setFeatures(e.detail.features); }
       if (e.detail.labFeatures) { if (mapRefPanel?.current) { mapRefPanel.current.getSource("labfeatures").setData(e.detail.labFeatures);  }
                                  setLabFeatures(e.detail.labFeatures); }
       if (e.detail.focused) { if (mapRefPanel?.current) { mapRefPanel.current.getSource("focused").setData(e.detail.focused);  }
                                  setFocused(e.detail.focused); }
       if (e.detail.min) { recalculate(e.detail.min,e.detail.max); }


        });

    console.log("bbox parse");    
    let bbox = JSON.parse(localStorage.getItem("boundingBox"));
    setTimeout(() => { setBounds(bbox); }, 200);
    setBounds(bbox);
    
  }, []);

  React.useEffect(() => {
    if (mapRefPanel?.current) {
      mapRefPanel.current.zoomTo(mapRefPanel.current.getZoom());
      console.log('Rezoom');
    }
  }, [labFeatures, features]);

  React.useEffect(() => {
      recalculate(props.min,props.max);
  }, [props.min, props.max]);


  function resizeBoundingBox(bounds) {
    var point1 = turf.point([bounds[0], bounds[1]]);
    var point2 = turf.point([bounds[2], bounds[3]]);
    var bearing = turf.bearing(point1, point2);
    var distance = turf.distance(point1, point2);
    var p1 = turf.destination(point1, -0.5 * distance, bearing);
    var p2 = turf.destination(point2, 0.5 * distance, bearing);
    var box = [p1.geometry.coordinates[0], p1.geometry.coordinates[1], p2.geometry.coordinates[0], p2.geometry.coordinates[1]];
    return box;
  }



  function setBounds(bounds) {
    if ((mapRefPanel.current !== null) && (bounds)) {
      mapRefPanel.current.fitBounds(bounds, { duration: 0 });
      
    }
  }



  function printCanvas() {
    var dataUrl = mapRefPanel.current.getCanvas().toDataURL('image/png');
    var windowContent = '<!DOCTYPE html>';
    windowContent += '<html>'
    windowContent += '<head><title>Print canvas</title></head>';
    windowContent += '<body>'
    windowContent += '<img src="' + dataUrl + '">';
    windowContent += '</body>';
    windowContent += '</html>';
    const printWindow = window.open('', '_blank');
    printWindow.document.write(windowContent);
    printWindow.document.close();
    printWindow.print();

  }


  function downloadCanvas() {
    var dataUrl = mapRefPanel.current.getCanvas().toDataURL('image/png');
    let alink = document.createElement("a");
    alink.href = dataUrl;
    alink.download = "mapa.png";
    alink.click();
    window.URL.revokeObjectURL(dataUrl);

  }

  function recalculate(min,max) {
    setMin(min);
    setMax(max);
    setMed((min+max)/2);
    setStep((max-min)/4); 
  }


  function applyPreviewImage(image) {
    //localStorage.setItem('previewImage', image);
    //dispatchEvent(new Event('previewImageChanged'));
  }



  return (
    <Map
      ref={mapRefPanel}
      mapLib={import('mapbox-gl')}
      mapboxAccessToken="pk.eyJ1Ijoia2lsbGVyZWsiLCJhIjoiY2xrdXlkdDVrMDNmcjNkcXprcXNhaXAzaiJ9.PXuJL9WHJpLU4cJBCu8nhA"
      movingMethod="flyTo"
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
      style={style}
      attributionControl={false}
      preserveDrawingBuffer={true}
    >



<Source id="features" type="geojson" data={props.features}>
        <Layer {...{
          id: 'polygonfill',
          type: 'fill',
          filter: ['all'],
          source: { 'type': 'vector' },
          paint: {
            'fill-color': {
              property: props.selected,
              stops: [
                [min, '#ff0000'],
                [med, '#00ff00'],
                [max, '#0000ff']
              ]
            },
            'fill-opacity': 0.6
          }
        }} />

        <Layer {...{
          id: 'polygonlines',
          type: 'line',
          filter: ['all'],
          source: { 'type': 'vector' },
          paint: {
            'line-color': {
              property: props.selected,
              stops: [
                [min, '#ff0000'],
                [med, '#00ff00'],
                [max, '#0000ff']
              ]
            },
            'line-width': 1
          }
        }} />
      </Source>



      <Source id="labfeatures" type="geojson" data={labFeatures}>
        <Layer {...{
          id: 'labpolygonfill',
          type: 'fill',
          filter: ['all'],
          source: { 'type': 'vector' },
          paint: {
            'fill-color': {
              property: props.selected,
              stops: [
                [min, '#ff0000'],
                [med, '#00ff00'],
                [max, '#0000ff']
              ]
            },
            'fill-opacity': 0.4
          }
        }} />

        <Layer {...{
          id: 'labpolygonlines',
          type: 'line',
          filter: ['all'],
          source: { 'type': 'vector' },
          paint: {
            'line-color': {
              property: props.selected,
              stops: [
                [min, '#ff0000'],
                [med, '#00ff00'],
                [max, '#0000ff']
              ]
            },
            'line-width': 1
          }
        }} />
      </Source>




      <Source id="focused" type="geojson" data={focused}>
        <Layer {...{
          id: 'focuspolygonfill',
          type: 'fill',
          filter: ['all'],
          source: { 'type': 'vector' },
          paint: {
            'fill-color': {
              property: props.selected,
              stops: [
                [min, '#ff0000'],
                [med, '#00ff00'],
                [max, '#0000ff']
              ]
            },
            'fill-opacity': 0.9
          }
        }} />

        <Layer {...{
          id: 'focuspolygonlines',
          type: 'line',
          filter: ['all'],
          source: { 'type': 'vector' },
          paint: {
            'line-color': {
              property: props.selected,
              stops: [
                [min, '#ffcccc'],
                [med, '#ccffcc'],
                [max, '#ccccff']
              ]
            },
            'line-width': 2
          }
        }} />
      </Source>



      <div className='absolute bottom-2 right-16'>
        <Tooltip content="Drukuj mapę">
          <Button variant="filled" className='p-2 rounded-r-none bg-gray-100 hover:shadow-none hover:bg-blue-200' onClick={printCanvas} >
            <PrinterIcon className='w-4 h-4' color="black" />
          </Button>
        </Tooltip>
        <Tooltip content="Zapisz mapę">
          <Button variant="filled" className='p-2 rounded-l-none bg-gray-100 hover:shadow-none hover:bg-blue-200' onClick={downloadCanvas} >
            <InboxArrowDownIcon className='w-4 h-4' color="black" />
          </Button>
        </Tooltip>
      </div>

      {showLegend &&   
      <div className='flex flex-col absolute top-2 right-2 bg-gradient-to-b from-legend-min via-legend-med to-legend-max'>
        <div className="flex rounded-top-md w-8 h-4 items-center" >
        <Typography color="white" className="text-xs mx-auto mt-0.5">{min}</Typography>
        </div>
        <div className="flex w-6 h-4 items-center" >
        </div>

        <div className="flex rounded-none w-8 h-4 align-center" >
        <Typography color="white" className="text-xs mx-auto">{min+step}</Typography>
        </div>
        <div className="flex w-6 h-4 items-center" >
        </div>

        <div className="flex rounded-none w-8 h-4 items-center align-center" >
        <Typography color="white" className="text-xs mx-auto">{min+2*step}</Typography>
        </div>
        <div className="flex w-6 h-4 items-center" >
        </div>

        <div className="flex rounded-none w-8 h-4 items-center align-center" >
          <Typography color="white" className="text-xs mx-auto">{min+3*step}</Typography>
        </div>
        <div className="flex w-6 h-4 items-center" >
        </div>

        <div className="flex rounded-none w-8 h-4 mb-0.5" >
        <Typography color="white" className="text-xs mx-auto">{min+4*step}</Typography>
        </div>
      </div>
      }

    <NavigationControl position="bottom-right" />    
    </Map>

  );
}

