import * as React from 'react';
import { useState, useRef } from 'react';
import Map, { Source, Layer } from 'react-map-gl';






const fillStyle = {
  id: 'polygonfill',
  type: 'fill',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'fill-color': '#ffff80', // yellow color fill
    'fill-opacity': 0.5
  }
};

const lineStyle = {
  id: 'polygonlines',
  type: 'line',
  filter: ['all'],
  source: { 'type': 'vector' },
  paint: {
    'line-color': '#ffff80', //yellow line
    'line-width': 2
  }
};





export default function MapboxPreviewProps (props) {
  
  const [featureCollection] = useState(props.features);
  const [bbox] = useState(props.bbox);

  const mapRefPreview = useRef();


  React.useEffect(() => {
      setBounds(bbox);
      setTimeout(() => {
        setBounds(bbox);
        if (mapRefPreview.current) mapRefPreview.current.once('idle', getCanvas);
        }, 200);
},[]);  





  function setBounds(bounds) {
    if (mapRefPreview.current!==null) {
        mapRefPreview.current.fitBounds(bounds,{duration: 0});
    }
  }

  function applyPreviewImage(image) {
    localStorage.setItem('previewImage', image);
    dispatchEvent(new Event('previewImageChanged'));
}



  function getCanvas() {
    var img = mapRefPreview.current.getCanvas().toDataURL('image/png');
    applyPreviewImage(img);    
  }


  return (
    <Map
      ref={mapRefPreview}
      mapLib={import('mapbox-gl')}
      mapboxAccessToken="pk.eyJ1Ijoia2lsbGVyZWsiLCJhIjoiY2xrdXlkdDVrMDNmcjNkcXprcXNhaXAzaiJ9.PXuJL9WHJpLU4cJBCu8nhA"
      movingMethod="jumpTo"
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
      style={{ position: 'relative', width: '200px', height: '160px', top: 0 }}
      
      attributionControl={false}
      preserveDrawingBuffer={true}
    >
        
        <Source id="fc" type="geojson" data={featureCollection}>
          <Layer {...fillStyle} />
          <Layer {...lineStyle} />
        </Source>

    </Map>

  );
}

