import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  Alert,
  Card,
  Input,
  Checkbox,
  Button,
  IconButton,
  Typography,
} from "@material-tailwind/react";


import { FolderMinusIcon, ExclamationTriangleIcon,XMarkIcon } from '@heroicons/react/24/outline';

function PasswordChange(props) {

  const navigate = useNavigate();
  const [Login, setLogin] = useState('');
  const [Password, setPassword] = useState();
  const [secondPassword, setSecondPassword] = useState();
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("authenticated") || false);
  const [firstname, setFirstname] = useState(localStorage.getItem("firstname") || null);
  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [userid, setUserId] = useState(localStorage.getItem("userid") || 0);
  const [rank, setRank] = useState(localStorage.getItem("userid") || 0);

  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState();
  const [alertColor, setAlertColor] = useState('red');


  function handlePasswordChange(data) {

    console.log(data)
    if (data.status === "ok") {
        setMessage(data.message);
        setAlertColor('green');
        setAlert(true);
        const timer = setTimeout(() => {
            setAlert(false);
            props.close();
        }, 2000);
        return () => clearTimeout(timer);
    }
    if (data.status !== "ok") {
      setMessage(data.message);
      setAlert(true);
      const timer = setTimeout(() => {
        setAlert(false);
      }, 4000);
      return () => clearTimeout(timer);
    }

  }


  function cancelClose() {
    props.close();
  }


  function handleSubmit(e) {
    e.preventDefault();
    console.log('pass and sec');
    console.log(Password);
    console.log(secondPassword);
    if (Password && secondPassword)
        {
        if (Password==secondPassword)
            {
                if (Password.length<4)
                    {
                        setStatusMessage("Hasło nie może być krótsze niż 4 znaki");
                        return;
                    }
            }
            else
            {
                setStatusMessage("Wprowadzone hasła nie są takie same");
                return;
   
            }
        }
        else
        {
            console.log('at least one is empty');
            setStatusMessage("Wprowadź nowe hasło w oba pola");
            return;
        }
    
    setStatusMessage(null);        
    var bodydata = JSON.stringify({ action: 'changepass', password: Password, session });
    console.log(bodydata);
    fetch('https://agro-online.pl/cgi-bin/user.py', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: bodydata,
      mode: 'cors'

    })
      .then(res => res.json())
      .then(data => handlePasswordChange(data))
  }

  return (
    <>
              
            
              
        <div className='fixed h-screen w-full top-0 left-0 m-0 items-center shadow-md justify-cener flex '>
      
          <Card color="white" className="w-full max-w-[20rem] mx-auto px-2 py-2 shrink z-[30]" shadow={false}>
            <div className='flex flex-row items-center '>
            <Typography color="gray" className="mt-4 font-normal ml-6 mr-auto">
              Zmień hasło
            </Typography>
            <IconButton variant='text' className='top-0 ml-auto mr-1' color="blue-gray" data-refresh={false} onClick={cancelClose}>
                        <XMarkIcon className='w-6 h-6' />
             </IconButton>
             </div>
            <form className="mt-6 w-full max-w-[20rem] px-6 flex flex-col gap-6" onSubmit={handleSubmit}>
                <Input size="lg" type="password" label="Hasło" className="shadow-md" onChange={e => setPassword(e.target.value)} />
                <Input size="lg" type="password" label="Powtórz Hasło" className="shadow-md" onChange={e => setSecondPassword(e.target.value)} />
                <Typography color="gray" variant="small" className="relative -top-4 font-normal text-xs mx-auto text-red-700">
                {statusMessage}
                </Typography>

              <div>
                <Button className="flex items-center -top-4 mb-4 mx-auto w-[10rem] justify-center bg-gradient-to-br from-teal-500 to-cyan-500" fullWidth type="submit">
                  Zmień hasło
                </Button>
              </div>
            </form>
          </Card>
      </div>
      <Alert open={alert}
        color={alertColor}
        onClose={() => setAlert(false)}
        variant="gradient" className='absolute top-8 right-8 w-96 shadow-lg z-[100]'
        animate={{ mount: { y: 0 }, unmount: { y: 100 }, }}>
        <span className='inline-flex'><ExclamationTriangleIcon className='w-6 mr-2 mt-0.5' />{message}</span>
      </Alert>

    </>
  );
}

export default PasswordChange;