import React, { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  Input,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
  IconButton,
  MenuItem,
  Tooltip,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  ShoppingBagIcon,
  MapIcon,
  ClipboardDocumentListIcon,
  UserCircleIcon,
  DocumentDuplicateIcon,
  UsersIcon,
  Cog6ToothIcon,
  InboxIcon,
  InboxArrowDownIcon,
  PowerIcon,
  UserIcon,
  BanknotesIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  WrenchIcon
} from "@heroicons/react/24/outline";

import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import { FieldCard, FieldCardSmall } from "./ViewFieldCard";
import AgroTechnologyLogo from './agro-small.jpg'
import ClientCard from "./ClientCard";
import ClientsList from "./ClientsList";
import MemberCard from "./CrewCard";
import CrewList from "./CrewList";
import FieldsList from "./FieldsList";
import OffersList from "./OffersList";
import OrdersList from "./OrdersList";
import { showAlert } from "./Alert";
import DocumentsList from "./DocumentsList";
import ChatCard from "./ChatCard";

import { PdfPreview } from "./LabForm";
//import { SMSAPI } from 'smsapi';


import { getClients, getOffers, getOrders, getFields, getFieldsInfo, getVehicles, getDocuments } from "./AdminTools";
import PasswordChange from "./PasswordChange";
import { geoportalGetFeatureInfo } from "./geoportal";
import EmailCard from "./EmailCard";
import { VehiclesUpdater } from "./VehiclesUpdater";




const activeOrders = [
  {
    title: "Moje pole przy drodze",
    description:
      "01.08.2023 Wysłano zlecenie wykonania pomiaru",
  },
  {
    title: "Pole przy lesie",
    description:
      "03.08.2023 Wysłano zlecenie wykonania pomiaru",
  },
];


export function Sidebar() {
  const [open, setOpen] = React.useState(0);
  const [openClients, setOpenClients] = React.useState(false);
  const [openCrew, setOpenCrew] = React.useState(false);
  const [openFields, setOpenFields] = React.useState(false);
  const [openOrders, setOpenOrders] = React.useState(false);
  const [openOffers, setOpenOffers] = React.useState(false);
  const [openDocuments, setOpenDocuments] = React.useState(false);
  const [openChangePass, setOpenChangePass] = React.useState(false);
  const [openErrorReport, setOpenErrorReport] = React.useState(false);
  const [openChat, setOpenChat] = React.useState(false);
  
  const [openPdf, setOpenPdf] = React.useState(false);

  const [selectedClient, setSelectedClient] = React.useState(0);
  const [selectedClientName, setSelectedClientName] = React.useState('');
  const [selectedClientCity, setSelectedClientCity] = React.useState('');
  /*
  const [clients, setClients] = useState(JSON.parse(localStorage.getItem("clients")) || []);
  const [offers, setOffers] = useState(JSON.parse(localStorage.getItem("offers")) || []);
  const [orders, setOrders] = useState(JSON.parse(localStorage.getItem("orders")) || []);
  const [fields, setFields] = useState(JSON.parse(localStorage.getItem("orders")) || []);
  const [vehicles, setVehicles] = useState(JSON.parse(localStorage.getItem("vehicles")) || []);
  const [documents, setDocuments] = useState(JSON.parse(localStorage.getItem("documents")) || []);
  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [rank, setRank] = useState(localStorage.getItem("rank") || 0);
 */
  const [clients, setClients] = useState([]);
  const [offers, setOffers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [fields, setFields] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [session, setSession] = useState(localStorage.getItem("session") || null);
  const [rank, setRank] = useState(localStorage.getItem("rank") || 0);


  const navigate = useNavigate();

  React.useEffect(() => {
    if ((rank==10) && (session)) {
    console.log('sidebar');
    getClients(session, setClients);
    getOffers(session, setOffers);
    getOrders(session, setOrders);
    getFieldsInfo(session, setFields);
    //getVehicles(session, updateVehicles);
    getDocuments(session, setDocuments);
    
    console.log('time test');
  }

  }, []);

  /*
  function initVehicles()
    {
      const interval = setInterval(() => {
        let lock = localStorage.getItem("lockvehicles") || false;
        if (lock != "true") getVehicles(session, updateVehicles);
      }, 60000);
      return () => clearInterval(interval);
  
    }


  function updateVehicles(vehicles) {
    setVehicles(vehicles);
    dispatchEvent(new Event("vehiclesChanged"));
  }
  */

  function hideAll() {
    setOpenClients(false);
    setOpenCrew(false);
    setOpenDocuments(false);
    setOpenFields(false);
    setOpenOffers(false);
    setOpenOrders(false);
    setOpenErrorReport(false);
  }


  const handleOpenClients = () => {
    hideAll();
    setOpenClients(openClients === true ? false : true);
    console.log("get clients");
    getClients(session, setClients);
  };

  const handleOpenCrew = () => {
    hideAll();
    setOpenCrew(openCrew === true ? false : true);
  };

  const handleOpenDocuments = () => {
    hideAll();
    setOpenDocuments(openDocuments === true ? false : true);
  };

  const handleOpenFields = () => {
    hideAll();
    setOpenFields(openFields === true ? false : true);
  };

  const handleOpenOrders = () => {
    hideAll();
    setOpenOrders(openOrders === true ? false : true);
  };

  const handleOpenOffers = () => {
    hideAll();
    setOpenOffers(openOffers === true ? false : true);
  };

  const handleOpenErrorReport = () => {
    setOpenErrorReport(openErrorReport === true ? false : true);
  };

  const handleOpenChat = () => {
    setOpenChat(openChat === true ? false : true);
  };


  function logout() {
    localStorage.removeItem('session');
    localStorage.removeItem('rank');
    localStorage.removeItem('userid');
    localStorage.removeItem('authenticated');
    localStorage.removeItem('vehicles');
    localStorage.removeItem('clients');
    localStorage.clear();
    navigate('/')
  }


  const handleOnSelectClient = (id) => {
    if (id > 0) {
      var client = clients.filter(c => c.id === Number(id))[0];
      if (client) setSelectedClientName(client.name);
      setSelectedClient(id);
      setOpenClients();
    }
  };

  const unselectClient = () => {
    setSelectedClient(0);
    setOpenFields(false);
    getFields(session,() => void 0,0);
    getFieldsInfo(session,() => void 0,0);
    
  };



  const renderItems = activeOrders.map(({ title, description }) => (
    <a href="#" key={title}>
      <MenuItem>
        <Typography variant="h6" color="blue-gray" className="mb-1">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal">
          {description}
        </Typography>
      </MenuItem>
    </a>
  ));


  function MenuItem(props) {
    return (
      <ListItem className="h-12 w-[4.3rem] lg:w-auto" onClick={props.onClick}>
        <ListItemPrefix>
          {props.icon}
        </ListItemPrefix>
        <Typography color="blue-gray" className="mr-auto font-normal lg:visible collapse">
          {props.name}
        </Typography>
        <ChevronRightIcon strokeWidth={2.5} className={`mr-1 h-4 w-4 transition-transform ${props.isOpen === true ? "rotate-180" : ""}`} />
      </ListItem>
    )
  }


  function ClientItem() {
    return (
      <div className='flex flex-row m-0 p-0'>
        <ListItem className="h-10 ">
          <ListItemPrefix>
            <ChevronRightIcon className='h-[18px] w-[18px]' />
          </ListItemPrefix>
          <Typography variant="h5" className='text-sm max-w-[12rem]'>{selectedClientName}</Typography>
        </ListItem>
        <Tooltip content="Wyjdź z kontekstu klienta">
          <IconButton size="md" variant='text' className='' color="blue-gray" onClick={unselectClient}>
            <XMarkIcon className='w-6 h-6' />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  /*
    function renderPDF() {
      //ReactPDF.render(<LabFormDocument />, `example.pdf`);
      let stream = ReactPDF.renderToStream(<LabFormDocument />);
      console.log(stream);
    }
  
  
    async function sendTestSMS() {
      const smsapi = new SMSAPI('SxpBX2HpeqdtPnVd2egpeUFKlo6impbANwNjjA8P');
      
      try {
        const result = await smsapi.sms.sendSms('+48601147951', "Wiadomość żółwiowe źdźbło");
        console.log(result);
      } catch (err) {
        console.log(err);
      }
    }
  
  
    function sendTestEmail() {
      var bodydata = JSON.stringify({ action: "sendemail", session: session, address: "irek@internet.szczecin.pl", subject:"test agro-online" , text: "Wiadomość żółwiowe źdźbło", html:"<html>Wiadomość żółwiowe źdźbło</html>" });
  
      console.log(bodydata);
      fetch('https://agro-online.pl/cgi-bin/sms.py', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: bodydata,
        mode: 'cors'
      })
        .then(res => res.json())
        .then(data => console.log(data))
        .catch(error => {
          console.log({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });
    }
  */


  return (
    <>
      <VehiclesUpdater/>
      <Card className="h-[calc(100vh-2rem)] w-full max-w-[5rem] lg:max-w-[16rem] p-0 lg:p-4 shadow-xl top-[1rem] left-2 shadow-blue-gray-900/5 z-10 overflow-y-auto overflow-x-hidden">
        <div className="mb-2 p-4 flex mx-auto">
          <img src={AgroTechnologyLogo} alt="Agro-Technology" className="w-14 lg:w-36 flex self-center" />
        </div>
        <List className="w-auto">

          <MenuItem name="Klienci" icon={<UsersIcon className='h-[18px] w-[18px]' />} isOpen={openClients} onClick={handleOpenClients} />
          <MenuItem name="Pola" icon={<MapIcon className='h-[18px] w-[18px]' />} isOpen={openFields} onClick={handleOpenFields} />
          <MenuItem name="Zlecenia" icon={<ClipboardDocumentListIcon className='h-[18px] w-[18px]' />} isOpen={openOrders} onClick={handleOpenOrders} />
          <MenuItem name="Dokumenty" icon={<DocumentDuplicateIcon className='h-[18px] w-[18px]' />} isOpen={openDocuments} onClick={handleOpenDocuments} />
          <hr className="my-2 border-blue-gray-50" />
          {rank == 10 && //admins only
            <>
              <MenuItem name="Pracownicy" icon={<UsersIcon className='h-[18px] w-[18px]' />} isOpen={openCrew} onClick={handleOpenCrew} />
              <MenuItem name="Oferta" icon={<BanknotesIcon className='h-[18px] w-[18px]' />} isOpen={openOffers} onClick={handleOpenOffers} />
            </>
          }
          <hr className="my-2 border-blue-gray-50" />


          <ListItem className="w-[4.3rem] lg:w-auto disabled" onClick={handleOpenChat}>
            <ListItemPrefix>
              <InboxArrowDownIcon className="h-5 w-5" />
            </ListItemPrefix>
            <span className="lg:visible collapse">Wiadomości</span>
          </ListItem>

          <ListItem className="w-[4.3rem] lg:w-auto" >
            <ListItemPrefix>
              <Cog6ToothIcon className="h-5 w-5" />
            </ListItemPrefix>
            <span className="lg:visible collapse">Ustawienia</span>
          </ListItem>

          <ListItem className="w-[4.3rem] lg:w-auto" onClick={handleOpenErrorReport}>
            <ListItemPrefix>
              <WrenchIcon className="h-5 w-5 text-red-900" />
            </ListItemPrefix>
            <span className="lg:visible collapse text-red-900">Raportuj błąd</span>
          </ListItem>

          <hr className="my-2 border-blue-gray-50" />

          <ListItem color="red" className="w-[4.3rem] lg:w-auto" onClick={logout} >
            <ListItemPrefix>
              <PowerIcon className="h-5 w-5 " color="red" />
            </ListItemPrefix>
            <span className="text-red-500 lg:visible collapse">Wyloguj</span>
          </ListItem>



          {/* SelectedClient */}
          {selectedClient > 0 && (
            <>
              <hr className="mt-8 mb-4 border-blue-gray-200" />
              <ClientItem />
            </>
          )}


        </List>
      </Card>



      {openClients === true ? <ClientsList Close={setOpenClients} onSelect={handleOnSelectClient} /> : ''}
      {openCrew === true ? <CrewList Close={setOpenCrew} /> : ''}
      {openDocuments === true ? <DocumentsList Close={setOpenDocuments} /> : ''}
      {openFields === true ? <FieldsList Close={setOpenFields} clientid={selectedClient} /> : ''}
      {openOffers === true ? <OffersList Close={setOpenOffers} /> : ''}
      {openOrders === true ? <OrdersList Close={setOpenOrders} /> : ''}
      {openChangePass === true ? <PasswordChange close={setOpenChangePass} /> : ''}
      {openPdf === true ? <PdfPreview close={setOpenPdf} /> : ''}
      {openErrorReport === true ? <EmailCard close={setOpenErrorReport} name="wsparcie techniczne" email="irek@internet.szczecin.pl" subject="Wystąpił błąd w aplikacji" /> : ''}
      {openChat === true ? <ChatCard Close={()=>setOpenChat(false)} /> : ''}
    </>

  );
};

////Twilio pass: 2EgnA49o%4[(.u{!R@+!OL-/}OT8tsuN
////smsapi pass:  2EgnA49o%4[(.u{!R@+!OL-/}OT8tsuN
///smsapi token : SxpBX2HpeqdtPnVd2egpeUFKlo6impbANwNjjA8P	
